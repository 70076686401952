export const useBook = () => {
  return {
    isNextMonth(inputDate: string) {
      if (inputDate) {
        const date = new Date(inputDate)
        const now = new Date()
        const nextMonthDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)

        return (
          date.getFullYear() === nextMonthDate.getFullYear() &&
          date.getMonth() === nextMonthDate.getMonth()
        )
      }

      return false
    },
    isPrevMonth(inputDate: string) {
      if (inputDate) {
        const date = new Date(inputDate)
        const now = new Date()
        const prevMonthDate = new Date(now.getFullYear(), now.getMonth() - 1, 1)

        return (
          date.getFullYear() === prevMonthDate.getFullYear() &&
          date.getMonth() === prevMonthDate.getMonth()
        )
      }

      return false
    },
    isCurrentMonth(inputDate: string) {
      if (inputDate) {
        const date = new Date(inputDate)
        const now = new Date()

        return (
          date.getFullYear() === now.getFullYear() &&
          date.getMonth() === now.getMonth()
        )
      }

      return false
    },
    isOlderThanPrevMonth(inputDate: string) {
      if (inputDate) {
        const bookDate = new Date(inputDate)
        const now = new Date()
        const twoMonthsAgo = new Date(now)
        twoMonthsAgo.setMonth(now.getMonth() - 2)

        return bookDate < twoMonthsAgo
      }

      return false
    },
    genreToKey(genres: String[] = []) {
      const genre: string = genres.toString().toLocaleLowerCase()

      return genre.replaceAll('&', '').replaceAll(' ', '-')
    },
  }
}
