<template>
  <div class="book-prize d-flex flex-column align-items-center text-center">
    <PrizeIcon />
    <span class="d-block fw-semibold text-uppercase">
      {{ $label('books.bookPrize') }}
    </span>
    <p class="mb-0 text-small">{{ prize }}</p>
  </div>
</template>

<script>
import PrizeIcon from '~/assets/icons/prize.svg'

export default {
  components: {
    PrizeIcon,
  },
  props: {
    prize: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.book-prize {
  opacity: 0;
  background-color: $white;
  box-shadow: 0px 1.66135px 1.66135px rgba(0, 0, 0, 0.05);
  border-radius: 6.64538px;
  padding: 13.29px;
  position: absolute;
  z-index: 3;
  left: 146px;
  top: 24px;
  animation: fadeIn 0.3s 0.4s forwards ease-out;
}

svg {
  margin-bottom: 4px;
}

p {
  max-width: 110px;
}

@include media-breakpoint-up(md) {
  .book-prize {
    top: 8px;
  }
}

@include media-breakpoint-up(lg) {
  .book-prize {
    transform: rotate(8deg);
    left: 250px;
    top: 24px;
    width: fit-content;
  }
}
</style>
