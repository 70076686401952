<script setup>
import { ref } from 'vue'
import BaseLazyImage from '~/components/BaseLazyImage'
import BookPrize from '~/components/books/BookPrize'
import { getImagePlaceholder } from '~/utils/index'
import { useBook } from '~/composables/useBook'

const props = defineProps({
  cover: {
    type: String,
    required: true,
  },
  isAvailable: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
    default: 255,
  },
  height: {
    type: Number,
    default: 392,
  },
  title: {
    type: String,
    default: '',
  },
  genres: {
    type: Array,
    default: () => [],
  },
  prize: {
    type: String,
    default: '',
  },
  lazy: {
    type: Boolean,
    default: true,
  },
})

const image = ref(null)
function onError() {
  const placeholder = getImagePlaceholder()

  if (image.value) {
    image.value.src = placeholder
    image.value.srcset = placeholder
  }
}

const withBackground = !!props.genres.length
const { genreToKey } = useBook()
function getGenreClass() {
  return 'book__cover--with-background--' + genreToKey(props.genres)
}
</script>

<template>
  <div
    :class="[
      'book__cover position-relative',
      { 'book__cover--with-background': withBackground },
      { [getGenreClass()]: withBackground },
    ]"
  >
    <BookPrize v-if="prize" :prize="prize" />
    <BaseLazyImage
      v-if="lazy"
      :src="`${cover}&width=${width}&height=${height}&fit=cover`"
      :srcset="`${cover}&width=${width}&height=${height}&fit=cover 1x, ${cover}&width=${
        width * 2
      }&height=${height * 2}&fit=cover 2x`"
      :width="width"
      :height="height"
      :alt="title"
      fluid
    />

    <img
      v-else
      ref="image"
      :src="`${cover}&width=${width}&height=${height}&fit=cover`"
      :srcset="`${cover}&width=${width}&height=${height}&fit=cover 1x, ${cover}&width=${
        width * 2
      }&height=${height * 2}&fit=cover 2x`"
      :width="width"
      :height="height"
      fetchpriority="high"
      class="img-fluid"
      :alt="title"
      :title="title"
      @error="onError"
    />
  </div>
</template>

<style lang="scss" scoped>
.book__cover {
  width: fit-content;
}

img {
  box-shadow: 1px 3px 18px 0px rgba(0, 0, 0, 0.2);
}

.book__cover,
img {
  border-radius: 4px;
}

@include media-breakpoint-up(md) {
  .book__cover,
  img {
    border-radius: 8px;
  }

  .img {
    box-shadow: 1px 4px 24px 0px rgba(0, 0, 0, 0.2);
  }
}

@include generate-genre-classes(
  'background-color',
  'light',
  'book__cover--with-background'
);

.book__cover--with-background {
  padding: 7% 23.6%;
  box-shadow: unset;
  border-radius: 0;
}
</style>
